<template>
    <div class="col-12">
      <div class="content text-center">

        <!-- Overlaying Content -->
        <div class="overlaying-content">
          <div class="spark spark-top-1"></div>
          <div class="spark spark-top-2"></div>

          <div class="spark spark-top-3"></div>
          <div class="spark spark-top-4"></div>

          <div class="spark spark-bottom-1"></div>
          <div class="spark spark-bottom-2"></div>

          <div class="spark spark-bottom-3"></div>
          <div class="spark spark-bottom-4"></div>
        </div>

        <img class="logo" src="../assets/img/logo-grey.png" />
        <h1 class="title">404 ERROR</h1>
        <p class="description">OOPS! PAGE NOT FOUND.</p>
        <p class="paragraph">The page you were looking for doesn't exist.<br />You may have mistyped the address<br />or the page may have moved.</p>

        <router-link class="btn roundbtn"  to="/">RETURN TO HOMEPAGE</router-link>
      </div>
      
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "four-zero-four-content",
  components: {
  },
};
</script>

<style lang="scss">
@import '../assets/css/scss/404.scss';
</style>

