<template>
  <div class="page-404 row page">
    <Content />
  </div>
</template>

<script>
// @ is an alias to /src
import Content from '@/components/404.vue';

export default {
  name: "four-zero-four",
  head: {
    title: { inner: "four-zero-four" }
  },
  components: {
    Content
  },
};
</script>

<style lang="scss">
</style>

